@import "../sass/variaveis_cores";
@import "../sass/variaveis_fonts";
@import "../sass/mixins"; //MIXINS
// UTILIDADES
@import "../sass/utilities/utilitarios";
@import "../sass/utilities/responsivo";
@import "../sass/utilities/reset";
// @import "../sass/utilities/animation";

//components
@import "../sass/components/body"; //BODY
@import "../sass/components/menu";
@import "../sass/components/footer";
@import "../sass/components/login_avocado";
@import "../sass/components/banner";
@import "../sass/components/section_contato";
@import "../sass/components/section_clientes";
@import "../sass/components/section-title";
@import "../sass/components/section-midias";

// PÁGINAS
main {
  overflow: hidden;
}

.body__home {
  @import "./pages/page_home";
}
.body__sobre {
  @import "./pages/page_sobre";
}
.body__portfolio {
  @import "./pages/page_portfolio";
}
.body__servicos {
  @import "./pages/page_servico";
}
.body__abacateiro {
  @import "./pages/page_abacateiro";
}

.orn-sections{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// .body__clientes {
//     @import "./pages/page_clientes";
// }

// .body__trabalhos {
//     @import "./pages/page_trabalhos";
// }

// .body__servicos {
//     @import "./pages/page_servicos";
// }
// .body__sobre {
//     @import "./pages/page_sobre";
// }
